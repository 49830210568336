export const RECEIVE_KEGS = 'RECEIVE_KEGS'
export const LOAD_KEGS = 'LOAD_KEGS'


export function loadKegs () {
	return {
		type: LOAD_KEGS,
	}
}

export function receiveKegs (kegs) {
	return {
		type: RECEIVE_KEGS,
		kegs
	}
}