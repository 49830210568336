import { all, put, takeEvery } from 'redux-saga/effects';
import { LOAD_APP_DATA } from '../actions/shared'
import { LOAD_KEGS } from '../actions/kegs'
import { LOAD_ACTIVE_KEGS } from '../actions/activeKegs'
import { LOAD_POURS } from '../actions/pours'
import { LOAD_TIME_SERIES } from '../actions/timeSeries'
import { LOAD_DAY_OF_WEEK } from '../actions/dayOfWeek'
import { LOAD_TIME_OF_DAY } from '../actions/timeOfDay'
import { LOAD_KEG_SIZES } from '../actions/kegSizes'

function* fetchAppData() {

    let requests = [
      put({ type: LOAD_ACTIVE_KEGS }),
      put({ type: LOAD_KEGS }),
      put({ type: LOAD_POURS }),
      put({ type: LOAD_TIME_SERIES }),
      put({ type: LOAD_DAY_OF_WEEK }),
      put({ type: LOAD_TIME_OF_DAY }),
      put({ type: LOAD_KEG_SIZES }),
      ]

    yield all(requests)

}


export function* loadAppData() {
  yield takeEvery(LOAD_APP_DATA, fetchAppData);
}