import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux'
import moment from 'moment';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    // minWidth: 600,
  },
  cellLessPadding: {
    paddingRight: 24,
  },
  header: {
    paddingBottom: 12,
  }
};


const PoursTable = (props) => {
  const { classes } = props;
  const { pours, pour_ids, hasData } = props;

  return (
    <div>
    {hasData &&
      <React.Fragment>
        <Typography className={classes.header} color="primary" align="center" variant="h6" gutterBottom>Active Keg Pours</Typography>
        <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellLessPadding} variant="head">Date</TableCell>
              <TableCell className={classes.cellLessPadding} variant="head" align="left">Spot</TableCell>
              <Hidden xsDown>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Beer</TableCell>
              </Hidden>
              <TableCell variant="head" align="right">Amount Poured (Ozs)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pour_ids.map(pour_id => (
              <TableRow key={pour_id}>
                <TableCell className={classes.cellLessPadding}  align="left">{pours[pour_id].date_formatted}</TableCell>
                <TableCell className={classes.cellLessPadding}  align="left">{pours[pour_id].spot_name}</TableCell>
                <Hidden xsDown>
                  <TableCell className={classes.cellLessPadding}  align="left">{pours[pour_id].beer_name_combined}</TableCell>
                </Hidden>
                <TableCell align="right">{pours[pour_id].amount_ozs_rounded}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Paper>
        </React.Fragment>
    }
    {!hasData &&
        <React.Fragment>
          <ProgressSpinner />
        </React.Fragment>
    }
    </div>
  )

}


const mapStateToProps = (state, props) => {

  if (!state.pours || Object.keys(state.pours).length === 0 || !state.activeKegs || Object.keys(state.activeKegs).length === 0) {
    return {
      hasData: false,
      pours: {},
      pour_ids: []
    }
  }
  else {
    var active_keg_ids = Object.keys(state.activeKegs).map((keg_spot_id) => state.activeKegs[keg_spot_id].keg_id)

    return {
      hasData: true,
      pours: state.pours,
      pour_ids: Object.keys(state.pours).filter((pour_id) => active_keg_ids.includes(state.pours[pour_id].keg_id)).sort((a,b) => moment(state.pours[b].date) - moment(state.pours[a].date))
    }
  }

};

export default connect(mapStateToProps)(withStyles(styles)(PoursTable));