import React, { Component } from 'react';
import './App.css';
import KegDashboard from './KegDashboard'
require('dotenv').config();

class App extends Component {


	render() {
		return (
			<div>
				<KegDashboard />
			</div>
		);
	}
}


export default App;
