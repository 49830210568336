import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_KEG_SIZES, RECEIVE_KEG_SIZES } from '../actions/kegSizes'

function* fetchKegSizes(action) {

  const endpoint = '/api/kegs/sizes';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_KEG_SIZES, kegSizes: data.keg_sizes }),
  	]

  yield all(requests)
}

export function* loadKegSizes() {
  yield takeEvery(LOAD_KEG_SIZES, fetchKegSizes);
}