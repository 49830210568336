export const RECEIVE_DAY_OF_WEEK = 'RECEIVE_DAY_OF_WEEK'
export const LOAD_DAY_OF_WEEK = 'LOAD_DAY_OF_WEEK'


export function loadDayOfWeek () {
	return {
		type: LOAD_DAY_OF_WEEK,
	}
}

export function receiveDayOfWeek (dayOfWeek) {
	return {
		type: RECEIVE_DAY_OF_WEEK,
		dayOfWeek
	}
}