import { combineReducers } from 'redux'
import kegs from './kegs'
import activeKegs from './activeKegs'
import pours from './pours'
import timeSeries from './timeSeries'
import dayOfWeek from './dayOfWeek'
import timeOfDay from './timeOfDay'
import kegSizes from './kegSizes'

export default combineReducers({
	activeKegs,
	kegs,
	pours,
	timeSeries,
	dayOfWeek,
	timeOfDay,
	kegSizes,
})