import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_DAY_OF_WEEK, RECEIVE_DAY_OF_WEEK } from '../actions/dayOfWeek'

function* fetchDayOfWeek(action) {

  const endpoint = '/api/pours/analytics/dayofweekdetail';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_DAY_OF_WEEK, data: data.data }),
  	]

  yield all(requests)
}

export function* loadDayOfWeek() {
  yield takeEvery(LOAD_DAY_OF_WEEK, fetchDayOfWeek);
}