import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {subscribeToActivePour,subscribeToPourEnded} from '../socket';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux'


const styles = theme => ({
	amount: {
	    display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'baseline',
	    marginBottom: theme.spacing.unit * 2,
  },
  	paper: {
	    marginTop: theme.spacing.unit * 3,
	    marginBottom: theme.spacing.unit * 3,
	    padding: theme.spacing.unit * 2,
	    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
	      marginTop: theme.spacing.unit * 1,
	      marginBottom: theme.spacing.unit * 1,
	      padding: theme.spacing.unit * 3,
   	 },
   	},
   	buttonContainer: {
   		display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'baseline',
   	}
});


const RealTimePourModal = (props) => {
	const { classes } = props;
	const { activeKegs, kegs, hasData } = props;

	const [keg_spot_name, set_keg_spot_name] = useState(null)
	const [pour_amount, set_pour_amount] = useState(0.0)
	const [open, set_open] = useState(false)
	const [titleString, set_titleString] = useState("Pouring...")

	 const delayState = () => {
	 	setTimeout(() => {
	 		set_open(false)
	 		//set_pour_amount(0.0)
	 	}, 3000);
	 };

    subscribeToActivePour((err,keg_spot_id,pour_amount) => {
    	var pour_amount_rounded = parseFloat(pour_amount).toFixed(1);
    	var pour_amount_prior = parseFloat(pour_amount);
    	var pour_amount_float = parseFloat(pour_amount)
    	var keg_name = hasData ? kegs[activeKegs[keg_spot_id].keg_id].full_beer_name : keg_spot_id === 1 ? "Left Keg" : "Right Keg"
    	set_keg_spot_name(keg_name)
    	set_pour_amount(pour_amount_float > pour_amount_prior ? pour_amount_rounded : pour_amount_prior.toFixed(1))
    	set_open(true)
    	set_titleString("Pouring...")
	})
	subscribeToPourEnded((err,keg_spot_id,pour_amount) => {
		var pour_amount_rounded = parseFloat(pour_amount).toFixed(1);
		var keg_name = hasData ? kegs[activeKegs[keg_spot_id].keg_id].full_beer_name : keg_spot_id === 1 ? "Left Keg" : "Right Keg"
    	set_keg_spot_name(keg_name)
    	set_pour_amount(pour_amount_rounded)
    	set_open(true)
    	set_titleString("Enjoy!")		
		delayState();
	})

	const handleClose = () => {
	 	set_open(false)
	};




	return(


	    <React.Fragment>
		    <Dialog
		          open={open}
		          onClose={handleClose}
		          aria-labelledby="form-dialog-title"
		          maxWidth='md'
		     >
			    <DialogContent>	
			    	<Typography variant="h5" color="textPrimary" align="center">{titleString}</Typography>
			    	<Paper className={classes.paper}> 
			    		<div className={classes.amount}>
		                    <Typography variant="h1" color="textPrimary">
		                      {pour_amount}
		                    </Typography>
		                    <Typography variant="h6" color="textSecondary">
		                      ozs
		                    </Typography>
		                  </div>
		                <Typography variant="h6" color="textPrimary" align="center">{keg_spot_name}</Typography>
			    	</Paper>
			    	<div className={classes.buttonContainer}>
			    	<Button onClick={handleClose} color="primary" align="center">
              				Close
        			</Button>
        			</div>
		    	</DialogContent>
	        </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = (state, props) => {

	if (!state.activeKegs || Object.keys(state.activeKegs).length === 0 || !state.kegs || Object.keys(state.kegs).length === 0) {
		return {
			hasData: false,
			activeKegs: {},
			kegs: {}
		}
	}

	else {
		return {
			hasData: true,
			activeKegs: state.activeKegs,
			kegs: state.kegs
		}
	}

};

export default connect(mapStateToProps)(withStyles(styles)(RealTimePourModal));


