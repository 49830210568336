import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
// import classNames from 'classnames';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import KegProgressBar from './KegProgressBar'
import beer_colors from '../constants/beerColors';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  cardHeader: {
    // backgroundColor: theme.palette.grey[200],
    backgroundColor: "#9da1a7",
  }
});


const KegStatusCard = (props) => {
	const { classes } = props;
	const { data, scale } = props;

	return (
			<React.Fragment>
				<Card>
					 <CardHeader
		              title={data.brewer_name + " - " + data.beer_name}
		              subheader={data.beer_style}
		              titleTypographyProps={{ align: 'center' }}
		              subheaderTypographyProps={{ align: 'center' }}
		              className={classes.cardHeader}
		            />
		            <CardContent>
		              <div align="center">
		              	<KegProgressBar 
		              		fill_color={data.srm_color ? beer_colors[data.srm_color] : beer_colors[9]} 
		              		fill_progress={data.finish_date ? 0 : Math.floor(data.remaining_pct * 100)} 
		              		keg_spot_id={data.keg_spot_id} 
		              		srm_color={data.srm_color} 
		              		keg_size_id={data.keg_size_id}
		              		scale={scale} />
		                <Typography variant="h6" color="textPrimary">
		                  {data.finish_date ? 0 : Math.floor(data.remaining_ozs / 12)} beers remaining
		                </Typography>
		              </div>
		            </CardContent>
		            
	            </Card>
			</React.Fragment>

	)

}


export default withStyles(styles)(KegStatusCard);