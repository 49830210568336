import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_TIME_OF_DAY, RECEIVE_TIME_OF_DAY } from '../actions/timeOfDay'

function* fetchTimeOfDay(action) {

  const endpoint = '/api/pours/analytics/timeofday';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_TIME_OF_DAY, data: data.data }),
  	]

  yield all(requests)
}

export function* loadTimeOfDay() {
  yield takeEvery(LOAD_TIME_OF_DAY, fetchTimeOfDay);
}