import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux'



const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    // minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 16,
    paddingLeft: 16,
  }
};


const KegHistory = (props) => {
  const { classes } = props;
  const { kegs, keg_ids, hasData } = props;

  return (
    <div>
    {hasData &&
            <React.Fragment>
      <Typography className={classes.header} align="center" color="primary" variant="h6" gutterBottom>Keg History</Typography>
      <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <Hidden xsDown>
              <TableCell className={classes.cellLessPadding} variant="head">Brewer Name</TableCell>
            </Hidden>
            <TableCell className={classes.cellLessPadding} variant="head" align="left">Beer Name</TableCell>
            <Hidden xsDown>
              <TableCell className={classes.cellLessPadding} variant="head" align="left">Beer Style</TableCell>
            </Hidden>
            <Hidden xsDown>
              <TableCell className={classes.cellLessPadding} variant="head" align="left">Install Date</TableCell>
              <TableCell className={classes.cellLessPadding} variant="head" align="left">Finish Date</TableCell>
            </Hidden>
            <Hidden smUp>
              <TableCell className={classes.cellLessPadding} variant="head" align="left">Dates</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {keg_ids.map(keg_id => (
            <TableRow key={keg_id} >
              <Hidden xsDown>
                <TableCell className={classes.cellLessPadding} component="th" scope="row" >
                  {kegs[keg_id].brewer_name}
                </TableCell>
              </Hidden>
              <TableCell className={classes.cellLessPadding} align="left">
              <Hidden smUp>{kegs[keg_id].brewer_name} </Hidden>
                {kegs[keg_id].beer_name}
              
              </TableCell>
              <Hidden xsDown>
                <TableCell className={classes.cellLessPadding} align="left">{kegs[keg_id].beer_style}</TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell className={classes.cellLessPadding} align="left">{kegs[keg_id].install_date_clean}</TableCell>
                <TableCell className={classes.cellLessPadding} align="left">{kegs[keg_id].finish_date_clean}</TableCell>
              </Hidden>
              <Hidden smUp>
                <TableCell className={classes.cellLessPadding} align="left">{kegs[keg_id].install_date_clean} - {kegs[keg_id].finish_date_clean}</TableCell>
              </Hidden>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    </React.Fragment>
    }
    {!hasData &&
      <React.Fragment>
        <Typography className={classes.header} align="center" variant="h6" gutterBottom>Keg History</Typography>
        <ProgressSpinner />
      </React.Fragment>
    }
    </div>
  )

}

KegHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {


  if (!state.kegs || Object.keys(state.kegs).length === 0) {
    return {
      hasData: false,
      kegs: {},
      keg_ids: []
    }
  }

  else {
    return {
      hasData: true,
      kegs: state.kegs,
      keg_ids: Object.keys(state.kegs).sort((a,b) => moment(state.kegs[b].install_date) - moment(state.kegs[a].install_date))

    }
  }

};

export default connect(mapStateToProps)(withStyles(styles)(KegHistory));