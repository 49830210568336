import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_TIME_SERIES, RECEIVE_TIME_SERIES } from '../actions/timeSeries'

function* fetchTimeSeries(action) {

  const endpoint = '/api/pours/timeseries';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_TIME_SERIES, timeSeries: data.data }),
  	]

  yield all(requests)
}

export function* loadTimeSeries() {
  yield takeEvery(LOAD_TIME_SERIES, fetchTimeSeries);
}