import { RECEIVE_KEGS } from '../actions/kegs'
import moment from 'moment';

export default function kegs (state = {}, action) {

  switch(action.type) {
    case RECEIVE_KEGS :
      let newKegs = {}
      action.kegs.forEach((keg) => {
        newKegs[keg.keg_id] = {
          ...keg,
          install_date_clean: moment(keg.install_date).format('M/D/YY'),
          finish_date_clean: keg.finish_date ? moment(keg.finish_date).format('M/D/YY') : "",
          full_beer_name: keg.brewer_name + " " + keg.beer_name
        }
      }) 
      return {
        ...state,
        ...newKegs
      }

    default :
      return state
  }
}