import { all } from 'redux-saga/effects';
import { loadKegs } from './kegs'
import { loadActiveKegs, finishKeg, addKeg, editKeg } from './activeKegs'
import { loadAppData } from './shared'
import { loadPours } from './pours'
import { loadTimeSeries } from './timeSeries'
import { loadTimeOfDay } from './timeOfDay'
import { loadDayOfWeek } from './dayOfWeek'
import { loadKegSizes } from './kegSizes'


// replace the current rootSaga generator
export default function* rootSaga() {
  yield all([
  	loadKegs(),
  	loadActiveKegs(),
  	loadPours(),
  	loadTimeSeries(),
  	loadAppData(),
  	loadTimeOfDay(),
  	loadDayOfWeek(),
  	finishKeg(),
  	addKeg(),
  	editKeg(),
  	loadKegSizes(),
  	]);
}