import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_ACTIVE_KEGS, 
	RECEIVE_ACTIVE_KEGS, 
	FINISH_KEG, 
	FINISH_KEG_SUCCEEDED, 
	FINISH_KEG_FAILED,
	ADD_KEG,
	ADD_KEG_SUCCEEDED,
	ADD_KEG_FAILED,
	EDIT_KEG,
	EDIT_KEG_SUCCEEDED,
	EDIT_KEG_FAILED } from '../actions/activeKegs'
import { LOAD_APP_DATA } from '../actions/shared'

function* fetchActiveKegs(action) {

  const endpoint = '/api/kegs/active';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_ACTIVE_KEGS, kegs: data.kegs }),
  	]

  yield all(requests)
}

export function* loadActiveKegs() {
  yield takeEvery(LOAD_ACTIVE_KEGS, fetchActiveKegs);
}

function* postFinishKeg(action) {


	const token = action.token

	console.log(action)

	if (token) {
		const endpoint = '/api/kegs/kick/' + action.keg_id.toString();
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        }
		}

		try {
			yield call(fetch, endpoint, options);
			yield put({ type: FINISH_KEG_SUCCEEDED, keg_id: action.keg_id });
			yield put({ type: LOAD_APP_DATA });
		}
		catch {
			yield put({ type: FINISH_KEG_FAILED, keg_id: action.keg_id });
		}
	  	
	}

}

export function* finishKeg() {
  yield takeEvery(FINISH_KEG, postFinishKeg);
}


function* postAddKeg(action) {


	const token = action.token

	if (token) {
		const endpoint = '/api/kegs/add' 
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	      	body: JSON.stringify(action.keg)
		}

		try {
			yield call(fetch, endpoint, options);
			yield put({ type: ADD_KEG_SUCCEEDED, keg: action.keg });
			yield put({ type: LOAD_APP_DATA });
		}
		catch {
			yield put({ type: ADD_KEG_FAILED, keg: action.keg });
		}
	  	
	}

}

export function* addKeg() {
  yield takeEvery(ADD_KEG, postAddKeg);
}


function* postEditKeg(action) {

	const token = action.token

	if (token) {
		const endpoint = '/api/kegs/' + action.keg_id.toString();
		const options = {
			method: 'PUT',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	      	body: JSON.stringify(action.updatedKeg)
		}

		try {
			yield call(fetch, endpoint, options);
			yield put({ type: EDIT_KEG_SUCCEEDED });
			yield put({ type: LOAD_APP_DATA });
		}
		catch {
			yield put({ type: EDIT_KEG_FAILED });
		}
	  	
	}

}

export function* editKeg() {
  yield takeEvery(EDIT_KEG, postEditKeg);
}