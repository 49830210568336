import { RECEIVE_KEG_SIZES } from '../actions/kegSizes'

export default function kegSizes (state = {}, action) {

  switch(action.type) {
    case RECEIVE_KEG_SIZES :

      let newKegSizes = {}
      action.kegSizes.forEach((kegSize) => {
        newKegSizes[kegSize.keg_size_id] = {
          ...kegSize,
        }
      }) 
      return {
        ...newKegSizes
      }
    default :
      return state
  }
}