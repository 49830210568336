import React from 'react';
import KegStatusCard from './KegStatusCard'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});


const KegsStatus = (props) => {
	const { classes } = props;
	const { activeKegs, hasData } = props;

	return (
		<div>
		{hasData &&
				<div className={classes.root}>
				    <Grid container spacing={40} alignItems="center" justify="center">
			          {Object.keys(activeKegs).map((keg_spot_id) => (
			            <Grid item key={keg_spot_id} xs={12} sm={6}>
			              <KegStatusCard key={keg_spot_id} data={activeKegs[keg_spot_id]} scale={1.6} />
			            </Grid>
			          ))}
			        </Grid>
				</div>
		}
		{!hasData &&
			<React.Fragment>
		        <ProgressSpinner />
		    </React.Fragment>
		}
		</div>
	
	);
};


const mapStateToProps = (state, props) => {

	if (!state.activeKegs || Object.keys(state.activeKegs).length === 0) {
		return {
			hasData: false,
			activeKegs: []
		}
	}

	else {
		return {
			hasData: true,
			activeKegs: state.activeKegs
		}
	}

};

KegsStatus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(KegsStatus));