import React from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from '../Auth/Auth';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const styles = theme => ({
  userName: {
    paddingRight: 10, // keep right padding when drawer closed
  }
});

function SignInOut(props) {
  const { classes } = props;


  const signOut = () => {
    auth0Client.signOut();
    props.history.replace('/');
  };


  return (

    <div>
    {
      !auth0Client.isAuthenticated() &&
      <Button onClick={auth0Client.signIn} variant="contained">Sign In</Button>
    }
    {
      auth0Client.isAuthenticated() &&
      <div>
        <label className={classes.userName}>{auth0Client.getProfile().name}</label>
        <Button onClick={() => {signOut()}} variant="contained">Sign Out</Button>
      </div>
    }
    </div>
  );
}

SignInOut.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SignInOut));