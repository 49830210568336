import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
});

function ProgressSpinner(props) {
  const { classes } = props;
  return (
    <div className={classes.progressContainer}>
      <CircularProgress className={classes.progress} />
    </div>
  );
}

ProgressSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgressSpinner);