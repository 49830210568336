import React from 'react';
import AdminAddNewKeg from './AdminAddNewKeg';
import AdminActiveKegsTable from './AdminActiveKegsTable';
import Typography from '@material-ui/core/Typography';


function AdminConsole () {
    return (
      <div>
      	<Typography align="center" variant="h6" color="primary" gutterBottom>Manage Active Kegs</Typography>
        <br />
        <AdminAddNewKeg />
        <br />
        <AdminActiveKegsTable/>
      </div>
      );
}



export default AdminConsole;