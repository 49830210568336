import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import KegsStatus from './KegStatus/KegsStatus'
import AdminConsole from './AdminConsole/AdminConsole'
import KegHistory from './KegHistory/KegHistory'
import PoursTable from './PoursPage/PoursTable'
import PourTrendChart from './PoursPage/PourTrendChart'
import {Route, withRouter} from "react-router-dom";
import withRoot from './withRoot';
import auth0Client from './Auth/Auth';
import Callback from './Auth/Callback';
import SecuredRoute from './Auth/SecuredRoute'
import AdminNotLoggedIn from './AdminConsole/AdminNotLoggedIn';
import AppToolBar from './AppToolBar/AppToolBar';
import RealTimePourModal from './RealTimePour/RealTimePourModal';
import AnalyticsPage from './AnalyticsPage/AnalyticsPage';
import { loadAppData } from './actions/shared'
import { connect } from 'react-redux'
import {subscribeToPourEnded,unSubscribeToPourEnded} from './socket';
import Dashboard from './DashboardPage/Dashboard'


const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  }
});


class KegDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    }
    subscribeToPourEnded((err,keg_spot_id,pour_amount) => {
      this.props.dispatch(loadAppData())
    });
  }

 async componentDidMount() {
    if (this.props.location.pathname === '/callback') {
      this.props.dispatch(loadAppData());
      return;
    } 
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }
    this.props.dispatch(loadAppData())
  }
  
  componentWillUnmount() {
    unSubscribeToPourEnded((err,keg_spot_id,pour_amount) => {
      loadAppData()
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppToolBar />
        <main className={classes.content}>
          <RealTimePourModal />
          <div className={classes.appBarSpacer} />
            <Route exact path="/" component={Dashboard}/>
            <Route exact path="/status" component={KegsStatus}/>
            <Route path="/pours" component={PoursTable}/>
            <Route path="/trend" component={PourTrendChart}/>
            <Route path="/analytics" component={AnalyticsPage}/>
            <Route path="/kegs" component={KegHistory}/>
            <SecuredRoute path="/admin" component={AdminConsole} backupComponent={AdminNotLoggedIn} checkingSession={this.state.checkingSession} />
            <Route path="/callback" component={Callback}/>
        </main>
      </div>
    );
  }
}

KegDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withRouter(withRoot(withStyles(styles)(KegDashboard))));