export const RECEIVE_ACTIVE_KEGS = 'RECEIVE_ACTIVE_KEGS'
export const LOAD_ACTIVE_KEGS = 'LOAD_ACTIVE_KEGS'
export const FINISH_KEG = 'FINISH_KEG'
export const FINISH_KEG_SUCCEEDED = 'FINISH_KEG_SUCCEEDED'
export const FINISH_KEG_FAILED = 'FINISH_KEG_FAILED'
export const ADD_KEG = 'ADD_KEG'
export const ADD_KEG_SUCCEEDED = 'ADD_KEG_SUCCEEDED'
export const ADD_KEG_FAILED = 'ADD_KEG_FAILED'
export const EDIT_KEG = 'EDIT_KEG'
export const EDIT_KEG_SUCCEEDED = 'EDIT_KEG_SUCCEEDED'
export const EDIT_KEG_FAILED = 'EDIT_KEG_FAILED'


export function loadActiveKegs () {
	return {
		type: LOAD_ACTIVE_KEGS,
	}
}

export function receiveActiveKegs (activeKegs) {
	return {
		type: RECEIVE_ACTIVE_KEGS,
		activeKegs
	}
}

export function finishKeg (keg_id, token) {
	return {
		type: FINISH_KEG,
		keg_id,
		token
	}
}

export function addKeg (keg, token) {
	return {
		type: ADD_KEG,
		keg,
		token
	}
}

export function editKeg (keg_id, updatedKeg, existingKeg, token) {
	return {
		type: EDIT_KEG,
		keg_id,
		updatedKeg,
		existingKeg,
		token
	}
}