import io from 'socket.io-client';

const  socket = io();

function subscribeToActivePour(cb) {
  socket.on('activePour', (keg_spot_id,pour_amount) => cb(null, keg_spot_id, pour_amount));
  // socket.emit('subscribeToActivePour');
}

function subscribeToPourEnded(cb) {
  socket.on('pourEnded', (keg_spot_id,pour_amount) => cb(null, keg_spot_id, pour_amount));
  // socket.emit('subscribeToActivePour');
}

function unSubscribeToPourEnded(cb) {
  socket.off('pourEnded', (keg_spot_id,pour_amount) => cb(null, keg_spot_id, pour_amount));
  // socket.emit('subscribeToActivePour');
}

function subscribeToActiveKegsStatusChange(cb) {
	socket.on('activeKegStatusChange', () => cb(null));
}

function unSubscribeToActiveKegsStatusChange(cb) {
	socket.off('activeKegStatusChange', () => cb(null));
}


export { subscribeToActivePour, subscribeToPourEnded, unSubscribeToPourEnded, subscribeToActiveKegsStatusChange, unSubscribeToActiveKegsStatusChange };