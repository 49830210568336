const beer_colors = {
	1: "#fbe49b",
	2: "#f9d67e",
	3: "#f7c863",
	4: "#f5bf4e",
	5: "#eeb136",
	6: "#eda728",
	7: "#e59d26",
	8: "#dd9223",
	9: "#d78922", 
	10: "#d08121",
	11: "#c87621",
	12: "#c16f1e",
	13: "#bb671c",
	14: "#b4601c", 
	15: "#aa571a", 
	16: "#a65416",
	17: "#a14b18",
	18: "#964416",
	19: "#914015",
	20: "#8c3b14",
	21: "#863511",
	22: "#80330f",
	23: "#782d0e",
	24: "#74270c",
	25: "#6e230d",
	26: "#6a230b",
	27: "#641d09",
	28: "#5f180b",
	29: "#5a160a",
	30: "#531506",
	31: "#501409",
	32: "#561307",
	33: "#4a120d",
	34: "#450d0b",
	35: "#3f1009",
	36: "#3c0e09",
	37: "#360f08",
	38: "#350d0a",
	39: "#350c0d",
	40: "#300d0b",
}

export default beer_colors;