export const RECEIVE_POURS = 'RECEIVE_POURS'
export const LOAD_POURS = 'LOAD_POURS'


export function loadPours () {
	return {
		type: LOAD_POURS,
	}
}

export function receivePours (pours) {
	return {
		type: RECEIVE_POURS,
		pours
	}
}