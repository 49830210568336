import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_KEGS, RECEIVE_KEGS } from '../actions/kegs'

function* fetchKegs(action) {

  const endpoint = '/api/kegs';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_KEGS, kegs: data.kegs }),
  	]

  yield all(requests)
}

export function* loadKegs() {
  yield takeEvery(LOAD_KEGS, fetchKegs);
}