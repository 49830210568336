export const RECEIVE_TIME_OF_DAY = 'RECEIVE_TIME_OF_DAY'
export const LOAD_TIME_OF_DAY = 'LOAD_TIME_OF_DAY'


export function loadTimeOfDay () {
	return {
		type: LOAD_TIME_OF_DAY,
	}
}

export function receiveTimeOfDay  (timeOfDay) {
	return {
		type: RECEIVE_TIME_OF_DAY,
		timeOfDay
	}
}