import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import BarChart from 'recharts/lib/chart/BarChart';
import Bar from 'recharts/lib/cartesian/Bar';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'

const styles = {
  root: {
    paddingLeft: "15px",
    paddingTop: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    marginRight: "15px",
  },
  header: {
    paddingBottom: 12,
  }
};

const TimeOfDayChart = (props) => {
  const { classes } = props;
  const { timeOfDay, hasData } = props;
  var { chartHeight } = props;
  chartHeight = chartHeight ? chartHeight : 650  

  return (
  	<div>
  	{hasData &&
           <Paper className={classes.root}>
           <Typography className={classes.header} variant="h6" color="primary" gutterBottom>Time of Day</Typography>
          <ResponsiveContainer width="99%" height={chartHeight}>
            <BarChart width={200} height={250} data={Object.keys(timeOfDay).map((id) => timeOfDay[id])}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hourName" />
              <YAxis label={{ value: 'Total Beers', angle: -90, position: 'insideLeft', offset: 10 }} />
              <Tooltip />
              <Bar name="Total Beers" dataKey="total_beers_rounded" fill="#eeb136" />
            </BarChart>
            </ResponsiveContainer>
          </Paper>
  	}
  	{!hasData &&
      <React.Fragment>
        <ProgressSpinner />
      </React.Fragment>
  	}
  	</div>
  )

 }

 const mapStateToProps = (state, props) => {

  if (!state.timeOfDay || Object.keys(state.timeOfDay).length === 0) {
    return {
      hasData: false,
      timeOfDay: {},
    }
  }

  else {
    return {
      hasData: true,
      timeOfDay: state.timeOfDay

    }
  }

};

export default connect(mapStateToProps)(withStyles(styles)(TimeOfDayChart));
