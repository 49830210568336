import { RECEIVE_ACTIVE_KEGS } from '../actions/activeKegs'
import moment from 'moment';

export default function activeKegs (state = {}, action) {

  switch(action.type) {
    case RECEIVE_ACTIVE_KEGS :
      let newKegs = {}
      Object.keys(action.kegs).forEach((id) => {
        newKegs[action.kegs[id].keg_spot_id] = {
          ...action.kegs[id],
          install_date_clean: moment(action.kegs[id].install_date).format('M/D/YY'),
          keg_side: action.kegs[id].keg_spot_id===1 ? 'Left' : 'Right',
          poured_ozs_rounded: +action.kegs[id].poured_ozs.toFixed(2),
          remaining_ozs_rounded: +action.kegs[id].remaining_ozs.toFixed(2),
        }
      }) 
      return {
        ...state,
        ...newKegs
      }

    default :
      return state
  }
}