import React from 'react';
import Typography from '@material-ui/core/Typography';


function AdminNotLoggedIn () {
    return (
      <div>
      	<Typography align="center" variant="h6" gutterBottom>Manage Active Kegs</Typography>
        <Typography variant="body1" align="center" color="textPrimary">
          This page is for marking kegs as finished and adding new ones. 
          <br/>
          You must be Scott or Briana and be logged in to access the page.
        </Typography>
      </div>
      );
}

export default AdminNotLoggedIn;