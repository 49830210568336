import { RECEIVE_TIME_SERIES } from '../actions/timeSeries'

export default function timeSeries (state = {}, action) {

  switch(action.type) {
    case RECEIVE_TIME_SERIES :




      let newTimeSeries = {}
      action.timeSeries.forEach((item) => {

        if (!(item.keg_id in newTimeSeries)) {
          newTimeSeries[item.keg_id] = {}
        }

        newTimeSeries[item.keg_id][item.date] = {
          amount_consumed_ozs: item.amount_consumed_ozs,
          amount_remaining_ozs: item.amount_remaining_ozs,
          amount_remaining_ozs_rounded: !item.amount_remaining_ozs ? item.amount_remaining_ozs : item.amount_remaining_ozs.toFixed(0),
          beers_remaining_rounded: !item.amount_remaining_ozs ? 0.0 : (item.amount_remaining_ozs / 12.0).toFixed(1),
 
        }

      }) 
      return {
        ...state,
        ...newTimeSeries
      }

    default :
      return state
  }
}