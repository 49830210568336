import thunk from 'redux-thunk'
import logger from './logger'
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware } from 'redux'
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();


export default process.env.NODE_ENV === 'development' ? applyMiddleware(
  sagaMiddleware,
  thunk,
  logger,
) :
applyMiddleware(
  sagaMiddleware,
  thunk,
  logger,
)

export function runSaga() {
	sagaMiddleware.run(rootSaga);
}