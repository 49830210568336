import { RECEIVE_POURS } from '../actions/pours'
import moment from 'moment';

export default function pours (state = {}, action) {

  switch(action.type) {
    case RECEIVE_POURS :
      let newPours = {}
      action.pours.forEach((pour) => {
        newPours[pour.pour_id] = {
          ...pour,
          date_formatted: moment(pour.date).format('ddd, M/D h:mm a'),
          beer_name_combined: pour.brewer_name + " - " + pour.beer_name + " (" + pour.beer_style + ")",
          spot_name: pour.keg_spot_id === 1 ? "Left" : "Right",
          amount_ozs_rounded: +pour.amount_ozs.toFixed(1)
        }
      }) 
      return {
        ...state,
        ...newPours
      }

    default :
      return state
  }
}