export const RECEIVE_TIME_SERIES = 'RECEIVE_TIME_SERIES'
export const LOAD_TIME_SERIES = 'LOAD_TIME_SERIES'


export function loadTimeSeries () {
	return {
		type: LOAD_TIME_SERIES,
	}
}

export function receiveTimeSeries (timeSeries) {
	return {
		type: RECEIVE_TIME_SERIES,
		timeSeries
	}
}