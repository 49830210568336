export const RECEIVE_KEG_SIZES = 'RECEIVE_KEG_SIZES'
export const LOAD_KEG_SIZES = 'LOAD_KEG_SIZES'


export function loadKegSizes () {
	return {
		type: LOAD_KEG_SIZES,
	}
}

export function receiveKegSizes (kegSizes) {
	return {
		type: RECEIVE_KEG_SIZES,
		kegSizes
	}
}