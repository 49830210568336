import { RECEIVE_DAY_OF_WEEK } from '../actions/dayOfWeek'

const dayNames = ["M","Tu","W","Th","F","Sa","Su"];

export default function dayOfWeek (state = {}, action) {

  switch(action.type) {
    case RECEIVE_DAY_OF_WEEK :

      let newData = action.data.map((item) => ({
        ...item,
        dayName: dayNames[item.wkday],
        beers_per_period_rounded: !item.beers_per_period ? item.beers_per_period : item.beers_per_period.toFixed(1),
        beers_per_period_active_rounded: !item.beers_per_period_active ? item.beers_per_period_active : item.beers_per_period_active.toFixed(1),
        total_ozs_rounded: !item.total_ozs ? item.total_ozs : item.total_ozs.toFixed(0),
      }))
      return {
        ...newData
      }

    default :
      return state
  }
}