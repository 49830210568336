import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import auth0Client from '../Auth/Auth';
import ProgressSpinner from '../utilities/ProgressSpinner'
import { finishKeg } from '../actions/activeKegs'
import { connect } from 'react-redux'
import AdminEditKeg from './AdminEditKeg'

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
   button: {
      margin: theme.spacing.unit,
    },
});


const AdminActiveKegsTable = (props) => {
  const { classes } = props;
  const { hasData, activeKegs, dispatch } = props;

  const kickKeg = (keg_id) => {
    var token = auth0Client.getIdToken();
    dispatch(finishKeg(keg_id, token))

  }

  return (
    <div>
    {hasData && 
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell variant="head"></TableCell>
              <TableCell variant="head">Brewer Name</TableCell>
              <TableCell variant="head" align="left">Beer Name</TableCell>
              <TableCell variant="head" align="left">Beer Style</TableCell>
              <TableCell variant="head" align="left">Install Date</TableCell>
              <TableCell variant="head" align="left">Keg Capacity</TableCell>
              <TableCell variant="head" align="left">Consumed Ounces</TableCell>
              <TableCell variant="head" align="left">Remaining Ounces</TableCell>
              <TableCell variant="head" align="left">Keg Side</TableCell>
              <TableCell variant="head" align="left">Finish Keg</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(activeKegs).map(keg_spot_id => (
              <TableRow key={keg_spot_id}>
              <TableCell align="left">
                <AdminEditKeg keg={activeKegs[keg_spot_id]} />
              </TableCell>
                <TableCell component="th" scope="row" >{activeKegs[keg_spot_id].brewer_name}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].beer_name}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].beer_style}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].install_date_clean}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].capacity_ozs}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].poured_ozs_rounded}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].remaining_ozs_rounded}</TableCell>
                <TableCell align="left">{activeKegs[keg_spot_id].keg_side}</TableCell>
                <TableCell align="left">
                  <Button 
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={activeKegs[keg_spot_id].finish_date ? true : false}
                    id={activeKegs[keg_spot_id].keg_id}
                    name={activeKegs[keg_spot_id].keg_id}
                    onClick={() => kickKeg(activeKegs[keg_spot_id].keg_id)}
                  >
                  Kick
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    }
    {!hasData && 
        <React.Fragment>
          <ProgressSpinner />
        </React.Fragment>
    }
    </div>
  )

}

 const mapStateToProps = (state, props) => {

  if (!state.activeKegs || Object.keys(state.activeKegs).length === 0) {
    return {
      hasData: false,
      activeKegs: {},
    }
  }

  else {
    return {
      hasData: true,
      activeKegs: state.activeKegs

    }
  }

};


export default connect(mapStateToProps)(withStyles(styles)(AdminActiveKegsTable));
