import React from 'react';
import KegStatusCard from '../KegStatus/KegStatusCard'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import { connect } from 'react-redux'
import DayOfWeekChart from '../AnalyticsPage/DayOfWeekChart'
import TimeOfDayChart from '../AnalyticsPage/TimeOfDayChart'
import PourTrendChart from '../PoursPage/PourTrendChart'


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});


const Dashboard = (props) => {
	const { classes } = props;
	const { activeKegs, hasData } = props;

	return (
		<div>
		{hasData &&
				<div className={classes.root}>
				    <Grid container spacing={40} alignItems="center" justify="center">
			          {Object.keys(activeKegs).map((keg_spot_id) => (
			            <Grid item key={keg_spot_id} xs={12} sm={4}>
			              <KegStatusCard key={keg_spot_id} data={activeKegs[keg_spot_id]} />
			            </Grid>
			          ))}
			          <Grid item sm={4} xs={12}>
			          	<DayOfWeekChart chartHeight={400}  />
			          </Grid>
			          <Grid item sm={8} xs={12}>
			          	<PourTrendChart chartHeight={350} useBeerColors={true} />
			          </Grid>
			          <Grid item sm={4} xs={12}>
			          	<TimeOfDayChart chartHeight={350}  />
			          </Grid>
			        </Grid>
				</div>
		}
		{!hasData &&
			<React.Fragment>
		        <ProgressSpinner />
		    </React.Fragment>
		}
		</div>
	
	);
};


const mapStateToProps = (state, props) => {

	if (!state.activeKegs || Object.keys(state.activeKegs).length === 0) {
		return {
			hasData: false,
			activeKegs: []
		}
	}

	else {
		return {
			hasData: true,
			activeKegs: state.activeKegs
		}
	}

};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));