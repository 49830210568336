import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
// import TableChartIcon from '@material-ui/icons/TableChart';
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';
import {NavLink} from "react-router-dom";
import KegIcon from './kegIcon'
import BeerIcon from './beerIcon'
import TrendIcon from './trendIcon'
import DashboardIcon from '@material-ui/icons/Dashboard';

export const menuItems = (
  <div>
      <ListItem button component={NavLink} to="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button component={NavLink} to="/status">
        <ListItemIcon>
          <KegIcon />
        </ListItemIcon>
        <ListItemText primary="Keg Status" />
      </ListItem>
      <ListItem button component={NavLink} to="/pours">
        <ListItemIcon>
          <BeerIcon />
        </ListItemIcon>
        <ListItemText primary="Pours" />
      </ListItem>
      <ListItem button component={NavLink} to="/trend">
        <ListItemIcon>
          <TrendIcon />
        </ListItemIcon>
        <ListItemText primary="Trend" />
      </ListItem>
      <ListItem button component={NavLink} to="/analytics">
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Analytics" />
      </ListItem>
      <ListItem button component={NavLink} to="/kegs">
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText primary="Keg History" />
      </ListItem>
      <ListItem button component={NavLink} to="/admin">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItem>
  </div>
);
