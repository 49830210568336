import React, { useState } from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import AreaChart from 'recharts/lib/chart/AreaChart';
import Area from 'recharts/lib/cartesian/Area';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import beer_colors from '../constants/beerColors';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
    paddingLeft: "15px",
    paddingTop: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
  },
  header: {
    paddingBottom: 12,
  },
  selectChart: {
    minWidth: 220,
    paddingBottom: "10px"
  },
  chartSettings: {
    float: "right"
  },
};

// indigo, teal, blue, green, deepPurple, orange, red, light blue, light green, amber, cyan, lime, deepOrange, brown, gray, yellow, purple, pink
const chartColorArray = ['#3f51b5','#009688', '#2196f3', '#4caf50','#673ab7','#ff9800'
  , '#f44336', '#03a9f4', '#8bc34a', '#ffc107' ,'#00bcd4', '#cddc39'
  , '#ff5722', '#795548' , '#9e9e9e', '#ffeb3b', '#9c27b0', '#e91e63'];



const PourTrendChart = (props) => {
  const { classes } = props;
  var { chartHeight } = props;
  chartHeight = chartHeight ? chartHeight : 750
  var { useBeerColors } = props;
  useBeerColors = useBeerColors ? useBeerColors : false;
  const { timeSeriesFormatted, timeSeriesFormattedActive, keg_ids, active_keg_ids, kegs, hasData } = props;
  const [activeOnly, setActiveOnly] = useState(true)

  const chartData = activeOnly ? timeSeriesFormattedActive : timeSeriesFormatted
  const chartKegIds = activeOnly ? active_keg_ids : keg_ids

  const allowForAllKegs = false

  return (
      <div>
      {hasData && 
      <React.Fragment>
      <Paper className={classes.root}>
      <Grid container alignItems="center" alignContent="center" justify="space-between">
        <Grid item xs={12} sm={6}>
          <Typography className={classes.header} variant="h6" color="primary" gutterBottom>Pour Down Chart</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <div className={classes.chartSettings}>
        {allowForAllKegs && 
          <Select
            value={activeOnly}
            onChange={(event) => setActiveOnly(event.target.value)}
            className={classes.selectChart}
          >
            <MenuItem value={true}>Active Kegs</MenuItem>
            <MenuItem value={false}>All Kegs</MenuItem>
          </Select>
        }
        </div>
        </Grid>
      </Grid> 
      <ResponsiveContainer width="99%" height={chartHeight}>
        <AreaChart data={chartData}>
          <XAxis dataKey="date" />
          <YAxis label={{ value: 'Beers', angle: -90, position: 'insideLeft', offset: 10 }} />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {chartKegIds.map((keg_id, index) => {
            var colorIndex = index % chartColorArray.length
            var color = chartColorArray[colorIndex]
            if (useBeerColors) {
              color = beer_colors[kegs[keg_id].srm_color]
            }
            return (
              <Area key={keg_id} name={kegs[keg_id].full_beer_name} type="monotone" dataKey={keg_id} stroke={color} dot={false}  fill={color} fillOpacity={0.3} isAnimationActive={false} />
            )}
          )}
        
        </AreaChart>
      </ResponsiveContainer>
      </Paper>
      </React.Fragment>
    }
    {!hasData && 
      <React.Fragment>
        <ProgressSpinner />
      </React.Fragment>      
    }
      </div>
    )

}

const mapStateToProps = (state, props) => {


  if (!state.timeSeries || Object.keys(state.timeSeries).length === 0 || !state.kegs || Object.keys(state.kegs).length === 0 || !state.activeKegs || Object.keys(state.activeKegs).length === 0) {
    return {
      hasData: false,
      timeSeriesFormatted: [],
      timeSeriesFormattedActive: [],
      keg_ids: [],
      active_keg_ids: [],
      kegs: {}
    }
  }



  else {

    var active_keg_ids = Object.keys(state.activeKegs).map((keg_spot_id) => state.activeKegs[keg_spot_id].keg_id)

    var timeSeriesFormattedActive = []
    var minDateActive = null;
    var maxDateActive = null;

    active_keg_ids.forEach((keg_id) => {
      if (keg_id in state.timeSeries) {
        Object.keys(state.timeSeries[keg_id]).forEach((date) => {
          if (moment(date, 'YYYY-MM-DD') < minDateActive || minDateActive === null) {
            minDateActive = moment(date, 'YYYY-MM-DD')
          }
          if (moment(date, 'YYYY-MM-DD') > maxDateActive || maxDateActive === null) {
            maxDateActive = moment(date, 'YYYY-MM-DD')
          }
        })
      }
    })


    var datesArrayActive = []

    for (var i = minDateActive.subtract(1,'days'); i <= maxDateActive; i = i.add(1, 'days')) {
      datesArrayActive.push(moment(i))
    }

    datesArrayActive.forEach((i) => {
      var newDataPoint = {
        date: i.format('M/D/YY')
      }
      active_keg_ids.forEach((keg_id) => { 
        newDataPoint[keg_id] = state.timeSeries[keg_id][i.format('YYYY-MM-DD')] ? state.timeSeries[keg_id][i.format('YYYY-MM-DD')].beers_remaining_rounded : null
      })
      timeSeriesFormattedActive.push(newDataPoint)
    })


    var filtered_keg_ids = Object.keys(state.kegs).filter((keg_id) => keg_id > 300 )

    var timeSeriesFormatted = []
    var minDate = null;
    var maxDate = null;

    filtered_keg_ids.forEach((keg_id) => {
      if (keg_id in state.timeSeries) {
        Object.keys(state.timeSeries[keg_id]).forEach((date) => {
          if (moment(date, 'YYYY-MM-DD') < minDate || minDate === null) {
            minDate = moment(date, 'YYYY-MM-DD')
          }
          if (moment(date, 'YYYY-MM-DD') > maxDate || maxDate === null) {
            maxDate = moment(date, 'YYYY-MM-DD')
          }
        })
      }
    })


    var datesArray = []

    for (i = minDate.subtract(1,'days'); i <= maxDate; i = i.add(1, 'days')) {
      datesArray.push(moment(i))
    }

    datesArray.forEach((i) => {
      var newDataPoint = {
        date: i.format('M/D/YY')
      }
      filtered_keg_ids.forEach((keg_id) => { 
        newDataPoint[keg_id] = state.timeSeries[keg_id] && state.timeSeries[keg_id][i.format('YYYY-MM-DD')] ? state.timeSeries[keg_id][i.format('YYYY-MM-DD')].beers_remaining_rounded : null
      })
      timeSeriesFormatted.push(newDataPoint)
    })


    return {
      hasData: true,
      timeSeries: state.timeSeries,
      keg_ids: filtered_keg_ids,
      timeSeriesFormatted,
      timeSeriesFormattedActive,
      kegs: state.kegs,
      active_keg_ids
    }
  }

};


export default connect(mapStateToProps)(withStyles(styles)(PourTrendChart));