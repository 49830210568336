import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_POURS, RECEIVE_POURS } from '../actions/pours'

function* fetchPours(action) {

  const endpoint = '/api/pours';

  const response = yield call(fetch, endpoint);
  const data = yield response.json();

  let requests = [
  	put({ type: RECEIVE_POURS, pours: data.pours }),
  	]

  yield all(requests)
}

export function* loadPours() {
  yield takeEvery(LOAD_POURS, fetchPours);
}