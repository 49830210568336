import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const background_color = "#868686"

const styles = {
  whiteText: {
    color: "white",
    textShadow: "1px 1px 1px #868686",
    fontSize: "2.5rem"
  }, 
  container: {
    position: "relative",
    textAlign: "center",
    color: "white",
  },
  centered: {
    position: "absolute",
    top: "55.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: "bold",
  },
};

// Change scale number to change size


const KegProgressBar = (props) => {
  const { classes } = props;
  const { keg_size_id, fill_color, fill_progress, keg_spot_id } = props;

  var { scale } = props
  scale = scale ? scale : 0.94
  const height = 337 * scale;
  var width = 227 * scale;
  var scaleValX = 0.1 * scale;
  const scaleValY = 0.1 * scale;  


  if (keg_size_id === 2 || keg_size_id === 12) {
    width = width / 1.4
    scaleValX = scaleValX / 1.4
  } else if (keg_size_id === 22) {
    width = width / 1.2
    scaleValX = scaleValX / 1.2     
  }

  var panel_1_fill = fill_progress >= 2.0 ? fill_color : background_color
  var panel_2_fill = fill_progress >= 16.0 ? fill_color : background_color
  var panel_3_fill = fill_progress >= 34.0 ? fill_color : background_color
  var panel_4_fill = fill_progress >= 51.0 ? fill_color : background_color
  var panel_5_fill = fill_progress >= 68.0 ? fill_color : background_color
  var panel_6_fill = fill_progress >= 84.0 ? fill_color : background_color 
  var transform = keg_spot_id === 1 ? "translate(0," + height + ") scale(" + scaleValX + ",-" + scaleValY + ")" : "translate(" + width + "," + height+ ") scale(-" + scaleValX + ",-" + scaleValY + ")"

  const viewboxStr = "0 0 " + width + " " + height

  return (
  <div className={classes.container}>
        <span align="center"></span>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width={width} height={height} viewBox={viewboxStr}
         preserveAspectRatio="xMidYMid meet">

        <g transform={transform}
        fill={background_color} stroke="none">
        {/* Top section */}
        <path fill={panel_6_fill} d="M710 3274 c-455 -83 -604 -145 -626 -260 -4 -20 -4 -129 -2 -243 6
        -230 13 -261 69 -304 19 -14 96 -56 171 -93 118 -58 148 -68 215 -75 43 -5 99
        -11 124 -15 41 -6 49 -3 72 20 36 36 70 33 112 -9 40 -39 49 -41 235 -52 221
        -12 302 -5 623 53 285 52 429 111 473 196 28 54 45 434 23 528 -14 62 -40 86
        -144 136 -104 49 -176 69 -298 83 -48 6 -92 13 -96 16 -5 3 -98 14 -207 25
        -278 28 -573 26 -744 -6z m665 -54 c170 -10 552 -79 622 -112 48 -22 100 -76
        109 -112 8 -33 -20 -88 -55 -109 -80 -47 -461 -131 -659 -145 -140 -10 -507 3
        -647 23 -120 17 -248 49 -422 106 -109 36 -143 67 -143 129 0 33 5 41 35 59
        85 50 361 113 616 140 101 11 198 22 214 24 58 8 181 6 330 -3z m516 -581 c18
        -18 21 -30 17 -75 -4 -45 -11 -61 -37 -88 -18 -18 -49 -38 -69 -44 -37 -11
        -321 -29 -377 -24 -102 8 -124 107 -39 182 19 16 36 30 37 31 15 4 347 37 390
        38 46 1 61 -3 78 -20z"/>
      {/* Inside of top section */}
        <path fill={background_color} d="M995 3204 c-268 -28 -377 -44 -491 -69 -293 -66 -359 -110 -289 -193
        20 -24 51 -39 148 -71 67 -22 140 -42 162 -46 23 -3 100 3 179 14 76 12 176
        21 223 21 l86 0 -6 65 c-8 81 -7 82 144 90 140 8 149 4 158 -81 l6 -59 170 -7
        c94 -4 210 -14 259 -23 88 -16 89 -16 179 10 142 41 167 59 167 124 0 37 -50
        98 -97 118 -18 7 -129 33 -246 57 -172 36 -248 47 -391 55 -205 13 -201 13
        -361 -5z m-94 -86 c50 -14 73 -41 73 -82 0 -46 -27 -83 -68 -94 -19 -6 -110
        -20 -204 -33 -191 -25 -220 -21 -243 32 -17 43 4 136 34 150 27 12 230 36 311
        38 32 1 76 -4 97 -11z"/>
      {/* Top of keg knob */}
        <path fill={background_color} d="M1125 3003 c-94 -11 -115 -15 -115 -23 0 -19 96 -44 165 -44 74 1
        115 15 115 40 0 28 -65 39 -165 27z"/>
      {/* Rest of keg knob */}
        <path fill={background_color} d="M1020 2885 c0 -51 1 -54 33 -65 48 -18 199 -25 225 -11 19 10 22 20
        22 72 l0 60 -37 -8 c-47 -10 -155 -10 -205 -1 l-38 7 0 -54z"/>
        <path fill={background_color} d="M1311 2839 c-17 -47 -33 -52 -143 -47 -106 5 -158 20 -158 46 0 13
        -17 14 -117 8 -141 -8 -259 -27 -256 -41 3 -14 45 -21 217 -39 276 -29 644
        -17 801 26 22 6 57 12 78 14 37 2 55 9 46 18 -11 11 -147 26 -296 32 l-162 7
        -10 -24z"/>
      {/* Top through handle opening */}
        <path fill={background_color}d="M1655 2634 c-55 -7 -129 -16 -165 -19 -58 -5 -69 -10 -102 -42 -30
        -29 -38 -45 -38 -73 0 -41 7 -53 37 -69 45 -23 385 -6 442 23 43 23 71 71 71
        123 0 75 -41 85 -245 57z"/>
        <path fill={panel_5_fill} d="M105 2262 l0 -208 27 -41 c66 -100 177 -146 563 -237 183 -43 306
        -56 527 -56 205 0 405 22 555 60 115 29 145 41 176 69 10 9 57 36 104 61 131
        69 127 59 133 328 3 125 4 229 2 231 -1 3 -19 -12 -40 -32 -66 -64 -211 -111
        -502 -162 -299 -52 -359 -57 -545 -45 -232 16 -233 16 -268 56 -38 42 -58 43
        -99 4 -36 -35 -35 -35 -108 -15 -30 8 -79 15 -109 15 -47 0 -75 11 -230 87
        -97 48 -178 89 -181 90 -3 2 -5 -91 -5 -205z"/>
        <path fill={panel_4_fill} d="M80 1981 c0 -44 8 -79 26 -124 25 -59 26 -69 21 -185 l-5 -122 62
        -56 c33 -31 79 -65 101 -76 22 -12 56 -32 77 -45 52 -35 278 -89 408 -98 58
        -4 114 -12 125 -18 45 -23 582 -2 694 28 30 8 58 12 62 9 12 -7 182 38 197 53
        7 7 20 13 28 13 34 0 192 92 242 140 l52 52 0 116 c0 93 4 128 20 170 21 56
        27 137 10 147 -6 3 -21 -7 -35 -23 -13 -16 -57 -45 -98 -65 -41 -19 -91 -50
        -111 -67 -49 -44 -236 -92 -446 -115 -186 -21 -529 -16 -675 10 -106 18 -437
        105 -518 135 -78 29 -145 76 -185 130 -19 25 -38 47 -43 48 -5 2 -9 -24 -9
        -57z"/>
        <path fill={panel_3_fill} d="M2104 1449 c-132 -107 -419 -186 -756 -209 -269 -18 -415 -11 -713
        35 -182 28 -372 98 -447 164 -20 17 -40 31 -45 31 -18 0 -46 -154 -40 -217 6
        -55 9 -61 59 -105 90 -81 165 -113 415 -179 l228 -60 215 -9 c251 -10 454 1
        560 29 41 11 127 33 190 50 74 19 163 53 252 95 l137 65 14 49 c15 49 14 67
        -9 240 -4 28 -10 52 -14 52 -4 0 -25 -14 -46 -31z"/>
        <path fill={panel_2_fill} d="M124 1133 c4 -9 9 -107 12 -217 3 -111 7 -211 10 -223 7 -37 60 -88
        146 -144 157 -100 260 -135 512 -170 131 -18 184 -20 421 -16 308 5 330 9 574
        98 184 67 233 97 292 179 l47 65 8 208 c4 114 4 207 0 207 -3 0 -61 -27 -127
        -61 -81 -40 -154 -69 -222 -85 -55 -14 -139 -36 -186 -50 -121 -35 -383 -50
        -627 -36 -166 10 -195 15 -402 67 -256 65 -291 78 -383 145 -72 54 -85 60 -75
        33z"/>
        <path fill={panel_1_fill} d="M2092 620 c-51 -71 -86 -93 -257 -158 -247 -94 -348 -113 -625 -117
        -266 -4 -558 32 -720 89 -93 33 -225 107 -289 164 -27 23 -53 42 -57 42 -4 0
        -4 -31 -1 -70 5 -59 13 -81 50 -142 37 -62 56 -82 128 -131 149 -104 277 -155
        497 -202 84 -17 477 -21 622 -6 111 12 351 100 457 168 187 120 282 263 259
        388 -4 19 -10 35 -14 35 -5 0 -27 -27 -50 -60z"/>
        </g>

        </svg>
        <div className={classes.centered}>
          <Typography className={classes.whiteText} align="center" variant="h3">{fill_progress}%</Typography>
        </div>
      </div>
    )

}


export default withStyles(styles)(KegProgressBar);