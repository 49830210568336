import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DayOfWeekChart from './DayOfWeekChart'
import TimeOfDayChart from './TimeOfDayChart'

const styles = {
  chartsContainer: {
    flexGrow: 1,

  },
  header: {
    paddingBottom: 12,
  }
};

const AnalyticsPage = (props) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <Typography className={classes.header} align="center" color="primary" variant="h6" gutterBottom>Beer Drinking Analytics</Typography>
      
      <div className={classes.chartsContainer}>
      <Grid container spacing={40} alignItems="center" justify="center">
        <Grid item xs={12} sm={6}>
          <DayOfWeekChart />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TimeOfDayChart />
        </Grid>
      </Grid>
      </div>
      </React.Fragment>
    )
}


export default withStyles(styles)(AnalyticsPage);