import React from 'react';

const kegIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 392 382">
	<path fill="rgba(0, 0, 0, 0.54)" d="M 50.17,347.33
           C 50.17,347.33 50.17,305.67 50.17,305.67
             50.17,305.67 71.00,305.67 71.00,305.67
             71.00,305.67 71.00,222.33 71.00,222.33
             71.00,222.33 50.17,222.33 50.17,222.33
             50.17,222.33 50.17,180.67 50.17,180.67
             50.17,180.67 71.00,180.67 71.00,180.67
             71.00,180.67 71.00,118.17 71.00,118.17
             71.00,118.17 50.17,118.17 50.17,118.17
             50.17,118.17 50.17,34.83 50.17,34.83
             50.17,34.83 175.17,34.83 175.17,34.83
             175.17,34.83 216.83,34.83 216.83,34.83
             216.83,34.83 341.83,34.83 341.83,34.83
             341.83,34.83 341.83,118.17 341.83,118.17
             341.83,118.17 321.00,118.17 321.00,118.17
             321.00,118.17 321.00,180.67 321.00,180.67
             321.00,180.67 341.83,180.67 341.83,180.67
             341.83,180.67 341.83,222.33 341.83,222.33
             341.83,222.33 321.00,222.33 321.00,222.33
             321.00,222.33 321.00,305.67 321.00,305.67
             321.00,305.67 341.83,305.67 341.83,305.67
             341.83,305.67 341.83,347.33 341.83,347.33
             341.83,347.33 50.17,347.33 50.17,347.33M 300.17,76.50
           C 300.17,64.99 290.84,55.67 279.33,55.67
             279.33,55.67 237.67,55.67 237.67,55.67
             226.16,55.67 216.83,64.99 216.83,76.50
             216.83,88.01 226.16,97.33 237.67,97.33
             237.67,97.33 279.33,97.33 279.33,97.33
             290.84,97.33 300.17,88.01 300.17,76.50
             300.17,76.50 300.17,76.50 300.17,76.50 Z" />
	</svg>
);

export default kegIcon;