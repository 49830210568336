import { RECEIVE_TIME_OF_DAY } from '../actions/timeOfDay'

const hourNames = ["12A","1A","2A","3A","4A","5A","6A","7A","8A","9A","10A","11A","12P","1P","2P","3P","4P","5P","6P","7P","8P","9P","10P","11P"];

export default function timeOfDay (state = {}, action) {

  switch(action.type) {
    case RECEIVE_TIME_OF_DAY :

      let newData = {}

      action.data.forEach((item) => {

        var hour = item.hr === 0 ? 24 : item.hr

        newData[hour] = {
        ...item,
        hr: hour,
        hourName: hourNames[item.hr],
        total_ozs_rounded: !item.total_ozs ? item.total_ozs : item.total_ozs.toFixed(0),
        total_beers_rounded: !item.total_ozs ? (0).toFixed(2) : (item.total_ozs / 12.0).toFixed(1)
      }

      })      
      return {
        ...newData
      }

    default :
      return state
  }
}