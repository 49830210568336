import React  from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import BarChart from 'recharts/lib/chart/BarChart';
import Bar from 'recharts/lib/cartesian/Bar';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'

const styles = {
  root: {
    paddingLeft: "15px",
    paddingTop: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    marginRight: "15px",
  },
  header: {
    paddingBottom: 12,
  }
};

const DayOfWeekChart = (props) => {
  const { classes } = props;
  const { dayOfWeek, hasData } = props;
  var { chartHeight } = props;
  chartHeight = chartHeight ? chartHeight : 650

  return (
  	<div>
  	{hasData &&
          <Paper className={classes.root}>
          <Typography className={classes.header} variant="h6" color="primary" gutterBottom>Day of Week</Typography>
          <ResponsiveContainer width="99%" height={chartHeight}>
            <BarChart width={200} height={250} data={Object.keys(dayOfWeek).map((id) => dayOfWeek[id])}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dayName" />
              <YAxis label={{ value: 'Beers per Week', angle: -90, position: 'insideLeft', offset: 10 }}  />
              <Tooltip />
              <Legend />
              <Bar name="All Time" dataKey="beers_per_period_rounded" fill="#eeb136" />
              <Bar name="Active Kegs" dataKey="beers_per_period_active_rounded" fill="#d78922" />
            </BarChart>

          </ResponsiveContainer>
          </Paper>
  	}
  	{!hasData &&
      <React.Fragment>
        <ProgressSpinner />
      </React.Fragment>
  	}
  	</div>
  )

 }

 const mapStateToProps = (state, props) => {

  if (!state.dayOfWeek || Object.keys(state.dayOfWeek).length === 0) {
    return {
      hasData: false,
      dayOfWeek: {},
    }
  }

  else {
    return {
      hasData: true,
      dayOfWeek: state.dayOfWeek

    }
  }

};

export default connect(mapStateToProps)(withStyles(styles)(DayOfWeekChart));
