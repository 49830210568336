import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import auth0Client from '../Auth/Auth';
import { TwitterPicker } from 'react-color';
import beer_colors from '../constants/beerColors';
import { addKeg } from '../actions/activeKegs'
import { connect } from 'react-redux'


const styles = theme => ({
	  root: {
	    display: 'flex',
	    flexWrap: 'wrap',
	  },
	  formControl: {
	    margin: theme.spacing.unit,
	    minWidth: 120,
	  },
	  selectEmpty: {
	    marginTop: theme.spacing.unit * 2,
	  },
	    paper: {
	    
	    marginTop: theme.spacing.unit * 3,
	    marginBottom: theme.spacing.unit * 3,
	    padding: theme.spacing.unit * 2,
	    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
	      marginTop: theme.spacing.unit * 6,
	      marginBottom: theme.spacing.unit * 6,
	      padding: theme.spacing.unit * 3,
   	 },
  	},
  	layout: {
	    width: 'auto',
	    marginLeft: theme.spacing.unit * 4,
	    marginRight: theme.spacing.unit * 4,
	    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
	      width: 600,
	      marginLeft: 'auto',
	      marginRight: 'auto',
    },
  	},
	  buttons: {
	    display: 'flex',
	    justifyContent: 'flex-end',
	  },
	  button: {
	  	margin: theme.spacing.unit,
	  },
	  labelText: {
	  	color: 'rgba(0, 0, 0, 0.54)',
	  	textAlign: 'left'
	  },
	  colorPickerContainer: {
	  	display: 'inline-flex',
    	alignItems: 'flex-end',
	  },
	colorPickerElement: {
    	margin: 8,
    	display: 'inline-flex',
	  }, 
	  buttonContainer: {
	  	display: 'flex',
	  	justifyContent: 'center',
	  },
	          color: {
          width: 36,
          height: 14,
          borderRadius: 2,
          // background: beer_colors[9],
        },
        swatch: {
          marginLeft: 10,
          padding: 5,
          background: '#fff',
          borderRadius: 1,
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: 10,
        },
        cover: {
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },

	});

const AdminAddNewKeg = (props) => {
  const { classes } = props;
  const { kegSizes, dispatch } = props;
  const [open, setOpen] = useState(false)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [beerColor, setBeerColor] = useState(beer_colors[9])
  const [beerColorSrm, setBeerColorSrm] = useState(9)
  const beerColorsArray = Object.keys(beer_colors).map((id) => beer_colors[id]).slice(0,20)
  const [brewerName, set_brewerName] = useState("")
  const [beerName, set_beerName] = useState("")
  const [beerStyle, set_beerStyle] = useState("")
  const [kegSize, set_kegSize] = useState("")
  const [kegSide, set_kegSide] = useState("")

  const handleChange = event => {
    if (event.target.name === "brewerName") {
      set_brewerName(event.target.value)
    } else if (event.target.name === "beerName") {
      set_beerName(event.target.value)
    } else if (event.target.name === "beerStyle") {
      set_beerStyle(event.target.value)
    } else if (event.target.name === "kegSize") {
      set_kegSize(event.target.value)
    } else if (event.target.name === "kegSide") {
      set_kegSide(event.target.value)
    }
  };

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false)
  };

  const handleColorChange = (newColor) => {
    setBeerColor(newColor.hex)
    setDisplayColorPicker(false)
    setBeerColorSrm(beerColorsArray.indexOf(newColor.hex) + 1)
  };

  const submitAddKeg = () => {
    var token = auth0Client.getIdToken();
    var newKeg = {
      brewer_name: brewerName,
      beer_name: beerName,
      beer_style: beerStyle,
      keg_size_id: kegSize,
      keg_spot_id: kegSide,
      beer_color_srm: beerColorSrm,
    }

    dispatch(addKeg(newKeg, token))
    
    handleClose()

  };

 return (

    <div>
    <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add new active keg
        </Button>
    <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth='md'
     >
    <DialogContent> 
      
    <React.Fragment>
    <main className={classes.layout}>

      <Typography align="center" variant="h6" gutterBottom>
        Add a New Active Keg
      </Typography>
      <Paper className={classes.paper}> 
      <Grid container spacing={24}>
        <Grid item xs={6} >
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="keg-side">Keg Side</InputLabel>
            <Select
              required
              value={kegSide}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: 'kegSide',
                id: 'keg-side',
              }}
            >
              <MenuItem value={1}>Left</MenuItem>
              <MenuItem value={2}>Right</MenuItem>
            </Select>
        </FormControl>
        </Grid>
         <Grid item xs={6}>
         <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="keg-size">Keg Size</InputLabel>
            <Select
              required
              value={kegSize}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: 'kegSize',
                id: 'keg-size',
              }}
            >
            {Object.keys(kegSizes).map(keg_size_id => (
                <MenuItem key={keg_size_id} value={keg_size_id}>{kegSizes[keg_size_id].keg_size_name}</MenuItem>
            ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              required
              id="brewer-name"
              name="brewerName"
              label="Brewer Name"
              fullWidth
              autoComplete="brewer-name"
              onChange={handleChange}
            />
           </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              required
              id="beer-name"
              name="beerName"
              label="Beer Name"
              fullWidth
              autoComplete="beer-name"
              onChange={handleChange}
            />
         </FormControl>
        </Grid>
        <Grid item xs={6}>
           <FormControl fullWidth className={classes.formControl}>
            <TextField
              required
              id="beer-style"
              name="beerStyle"
              label="Beer Style"
              fullWidth
              autoComplete="beer-style"
              onChange={handleChange}
            />
           </FormControl>
        </Grid>

         <Grid item xs={6} className={classes.colorPickerContainer}>
         <div className={classes.colorPickerElement}>
             <Typography align="center" variant="body1" className={classes.labelText}>
            Beer Color:
          </Typography>
            <div>
            <div className={ classes.swatch } onClick={ handleColorClick }>
              <div className={ classes.color } style={{background: beerColor}} />
            </div>
            { displayColorPicker ? <div className={ classes.popover }>
              <div className={ classes.cover } onClick={ handleColorClose }/>
              <TwitterPicker width='140' color={ beerColor } onChangeComplete={ handleColorChange } colors={ beerColorsArray } />
            </div> : null }

          </div>
        </div>
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button onClick={handleClose} color="primary">
                Cancel
          </Button>
        </Grid>
    <Grid item xs={6} className={classes.buttonContainer}>
        <Button
            variant="contained"
            color="primary"
            onClick={submitAddKeg}
            className={classes.button}
         > Add Keg
        </Button>
        </Grid>

      </Grid>
    </Paper>
      
    </main>

    </React.Fragment>
    </DialogContent>
        </Dialog>
      </div>

    );

}

 const mapStateToProps = (state, props) => {

  if (!state.kegSizes || Object.keys(state.kegSizes).length === 0) {
    return {
      hasData: false,
      kegSizes: {},
    }
  }

  else {
    return {
      hasData: true,
      kegSizes: state.kegSizes

    }
  }

};

export default connect(mapStateToProps)(withStyles(styles)(AdminAddNewKeg));

